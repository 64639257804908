import { RiPieChart2Line, Stack } from 'component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectStatus } from '@/api/rest/resources/types/project';
import { Logger } from '@/lib/logs/logger';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

export const NoProjectData = () => {
  const { t } = useTranslation();
  const project = useProjectDetailById().data;
  const projectStatus = project.status;

  const label = useMemo(() => {
    const exhaustivenessCheck = (v: never) => {
      Logger.error(`Invalid project status received: ${v}`);
      return t('global.analysis.noData');
    };

    const getLabelFromStatus = (status: ProjectStatus) => {
      switch (status) {
        case ProjectStatus.initial:
        case ProjectStatus.in_preparation:
          return t('global.analysis.noAnalysis');
        case ProjectStatus.scheduled_for_analysis:
          return t('global.analysis.calculationInProgress');
        case ProjectStatus.analysed:
        case ProjectStatus.ready_to_analyse:
        case ProjectStatus.activated:
        case ProjectStatus.invested:
        case ProjectStatus.locked:
          return t('global.analysis.noData');
        default:
          return exhaustivenessCheck(status);
      }
    };

    /**
     * For the first analysis of a project that has had no previous analyses, we need to show "No analysis yet" since there is no data
     * A special check needs to be added for this outside of checking for the project status
     * - We use the last_analyzed_at property of a project, which is null if a project has had no previous analysis
     * - We also check if the project status matches any of the states that appear before an analysis is triggered
     *   eg: last_analysed_at is still null when a project is scheduled_for_analysis,
     *       but in that case we would want to show "Calc in progress" instead, to indicate that an analysis is in progress
     */

    const noPreviousAnalysis = project.last_analyzed_at === null;
    const noOngoingAnalysis = [
      ProjectStatus.initial,
      ProjectStatus.in_preparation,
      ProjectStatus.ready_to_analyse,
    ].includes(projectStatus);
    const isFirstAnalysisPending = noPreviousAnalysis && noOngoingAnalysis;

    return isFirstAnalysisPending ? t('global.analysis.noAnalysis') : getLabelFromStatus(projectStatus);
  }, [projectStatus, project.last_analyzed_at, t]);

  return (
    <Stack
      data-testid='no-project-data'
      center
      spacing={4}
      className='h-[337px] min-h-[160px] items-center justify-center'
    >
      <span className='flex h-12 w-12 items-center justify-center rounded-full bg-neutral-black-8'>
        <RiPieChart2Line size={20} className='text-text-secondary' />
      </span>
      <span className='typography-h3 text-text-secondary'>{label}</span>
    </Stack>
  );
};
