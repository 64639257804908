import { RiCompass4Line, Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCard/NCCard';
import { EcologicalIntegrityExplainer } from '@/components/NCExplainers/EcologicalIntegrityExplainer';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { printMonthYear } from '@/utils/formatting/date';
import { range } from '@/utils/range';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from '../../../components/Chart';
import { FactCapsule } from '../../../components/FactCapsule';
import { useFact } from '../../../hooks/useFact';
import { useLatestAnalysisDatestamp } from '../../../hooks/useLatestAnalysisDatestamp';

export const EcologicalIntegrityCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<EcologicalIntegrityExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.ecologicalIntegrity.historicalData.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const currentYear = useFact<number>('r1_conservation_current_year').value;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;
  const ecologicalIntegrityTimeseries = useFact<Record<string, number>>(
    'r1_conservation_ecological_integrity_ts',
  ).value;

  const years = range(baselineYear, currentYear);

  const chartData = years.map((year) => ({
    ecologicalIntegrity: (ecologicalIntegrityTimeseries[`${year}`] ?? 0) || null,
    date: new Date(`${year}`),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.ecologicalIntegrity.historicalData.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          ecologicalIntegrity: {
            label: t('shared.ncaDetail.ecologicalIntegrity.historicalData.attributeLabel.ecologicalIntegrity'),
            color: '#61A84F',
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='ecologicalIntegrityFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#5CA847' stopOpacity={0.65} />
              <stop offset='100%' stopColor='#94D1A2' stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            stroke='var(--color-ecologicalIntegrity)'
            strokeWidth={3}
            fill='url(#ecologicalIntegrityFill)'
            fillOpacity={1}
            dataKey='ecologicalIntegrity'
            connectNulls
          />
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={[0, 1]}>
            <Chart.YAxisLabel>{t('shared.ncaDetail.ecologicalIntegrity.historicalData.value')}</Chart.YAxisLabel>
          </Chart.YAxis>

          <Chart.Tooltip
            content={(props) => (
              <Chart.TooltipContent
                {...props}
                labelFormatter={printMonthYear}
                formatter={(v) => `${getDisplayNumber((v as number) ?? 0)}`}
              />
            )}
          />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();
  const currentYear = useFact<number>('r1_conservation_current_year').value;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;
  const ecologicalIntegrityAverageFact = useFact<number>('r1_conservation_ecological_integrity');
  const ecologicalIntegrityAverage = ecologicalIntegrityAverageFact.value;
  const ecologicalIntegrityTimeseries = useFact<Record<string, number>>(
    'r1_conservation_ecological_integrity_ts',
  ).value;
  const ecologicalIntegrityBaseline = ecologicalIntegrityTimeseries[`${baselineYear}`] ?? ecologicalIntegrityAverage;

  const totalYears = currentYear - baselineYear;

  const changePercentage =
    (100 * (ecologicalIntegrityAverage - ecologicalIntegrityBaseline)) / ecologicalIntegrityBaseline;

  const trendExplanation = (() => {
    if (changePercentage === 0) {
      return 'shared.ncaDetail.ecologicalIntegrity.historicalData.explanationTrendConstant';
    }

    if (changePercentage > 0) {
      return 'shared.ncaDetail.ecologicalIntegrity.historicalData.explanationTrendPositive';
    }

    return 'shared.ncaDetail.ecologicalIntegrity.historicalData.explanationTrendNegative';
  })();

  const latestAnalysisDatestamp = useLatestAnalysisDatestamp();

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.ecologicalIntegrity.historicalData.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.ecologicalIntegrity.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.ecologicalIntegrity.body'),
          }}
          supplementaryInfo={latestAnalysisDatestamp}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <FactCapsule
            label={t('shared.ncaDetail.ecologicalIntegrity.historicalData.projectAverageValue')}
            fact={ecologicalIntegrityAverageFact}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.projectAverageValue.title'),
              body: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.projectAverageValue.body', {
                year: new Date().getFullYear() - 1,
              }),
            }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Trans
            i18nKey='shared.ncaDetail.ecologicalIntegrity.historicalData.explanation'
            values={{
              trendExplanation: `$t(${trendExplanation}, { "changePercentage": "${getDisplayNumber(
                changePercentage,
                undefined,
                {
                  signDisplay: 'always',
                },
              )}", "totalYears": "${totalYears}" })`,
            }}
          />
        }
      />
    </Stack>
  );
};
