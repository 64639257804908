import { ColumnDef } from '@tanstack/react-table';
import {
  ButtonLink,
  ButtonLinkProps,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  IconButton,
  RiMore2Fill,
  Stack,
} from 'component-library';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Plot } from '@/api/rest/resources/types/plot';
import { useScreenSize } from '@/hooks/useScreenSize';
import { isReactNode } from '@/utils/isReactNode';

import { PlotListDesktop } from './components/PlotListDesktop';
import { PlotListMobile } from './components/PlotListMobile';

export type PlotListProps = {
  plotFilters?: Record<string, unknown>;
  customColumns?: ColumnDef<Plot>[];
  plotListTitleProps?: PlotListTitleProps;
};

// TODO: MVP-3284: drive the plot list from the consumer
export const PlotList: FC<PlotListProps> = ({ plotFilters, customColumns, plotListTitleProps }) => {
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <Stack className='gap-0'>
      {plotListTitleProps && <PlotListTitle {...plotListTitleProps} />}
      {isSmallScreen ? (
        <PlotListMobile plotFilters={plotFilters} />
      ) : (
        <PlotListDesktop plotFilters={plotFilters} customColumns={customColumns} />
      )}
    </Stack>
  );
};

type PlotListTitleProps = {
  title: string;
  action?: ReactNode | ButtonLinkProps;
};

export const PlotListTitle: FC<PlotListTitleProps> = ({ title, action }) => {
  return (
    <Stack
      data-testid='plot-list-title'
      direction='row'
      spacing={4}
      centerMain
      className='min-h-[64px] items-center justify-between rounded-t-2xl bg-white-100 p-6'
    >
      <Stack direction='row' spacing={4} className='items-center'>
        <span className='typography-h3'>{title}</span>
      </Stack>
      <Stack direction='row' centerMain spacing={8}>
        {action && (isReactNode(action) ? action : <ListTitleActionButton {...action} />)}
      </Stack>
    </Stack>
  );
};

const ListTitleActionButton: FC<ButtonLinkProps> = ({ children, ...delegated }) => {
  const isLargeScreen = useScreenSize() === 'large';

  return isLargeScreen ? (
    <ButtonLink data-testid='list-title-action-desktop' {...delegated}>
      {children}
    </ButtonLink>
  ) : (
    <Dropdown>
      <DropdownTrigger asChild>
        <IconButton className='border text-primary-100' data-testid='list-title-action-mobile'>
          <RiMore2Fill size={24} />
        </IconButton>
      </DropdownTrigger>
      <DropdownContent align='end'>
        <DropdownItem>
          <Link {...delegated}>{children}</Link>
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
