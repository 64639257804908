import { ColumnDef } from '@tanstack/react-table';
import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/api/rest/resources/types/plot';
import { BiodiversityExplainer } from '@/components/NCExplainers/BiodiversityExplainer';
import { useScreenSize } from '@/hooks/useScreenSize';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { BiodiversityZoneCell } from '../../components/plot-list/components/Cells';
import { PlotListDesktop } from '../../components/plot-list/components/PlotListDesktop';
import { ToggleSortButton } from '../../components/plot-list/components/ToggleSortButton';
import { PlotListTitle } from '../../components/plot-list/PlotList';
import { BiodiversityCard } from './components/biodiversity/Card';

export const BiodiversityDetailPage = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const customPlotListColumns: ColumnDef<Plot>[] = [
    {
      id: 'biodiversityZone',
      accessorKey: 'biodiversityZone',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('global.analysis.protectedHabitat')}
        </ToggleSortButton>
      ),
      cell: BiodiversityZoneCell,
    },
  ];

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.biodiversity.labels.biodiversity')}>
      <Stack spacing={8}>
        <BiodiversityCard />
        <div>
          <PlotListTitle
            title={t('shared.ncaDetail.details.biodiversity.labels.biodiversityDataPerPlot')}
            action={!isSmallScreen ? <BiodiversityExplainer /> : null}
          />
          <PlotListDesktop customColumns={customPlotListColumns} />
        </div>
      </Stack>
    </LandMonitoringPageWrapper>
  );
};
