import { CellContext } from '@tanstack/react-table';
import {
  Alert,
  AlertBody,
  BiodiversityIcon,
  Button,
  ButtonLink,
  Co2Icon,
  Pill,
  RiArrowRightDownLine,
  RiArrowRightUpLine,
  RiCircleFill,
  RiErrorWarningFill,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  WaterIcon,
} from 'component-library';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PlotReportFactElement, R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { DraftPlot, Plot, PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { PlotTypeIcon, withSuspenseBoundary } from '@/components';
import { PlotThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { PendingPill } from '@/components/Pill/PendingPill';
import { getDisplayNumber, useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { Logger } from '@/lib/logs/logger';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatUnit, valueToTonne } from '@/utils/formatting';
import { printDDMMYYYYHHMM } from '@/utils/formatting/date';
import { getTrendIconColor, getTrendTextColor } from '@/utils/getColorForFact';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';
import {
  getColorOfPill,
  getColorOfPlotStatus,
  getColorOfPlotStatusNotice,
  getTextColorOfPill,
  getTextColorOfPlotStatusNotice,
} from '@/utils/plot/get-color-of-plot-status/getColorOfPlotStatus';

const maxDisplayCropCount = 3;

export const NameCell = ({ row }: CellContext<Plot, unknown> | CellContext<DraftPlot, unknown>) => {
  const { t } = useTranslation();
  const plot = row.original;

  const createdAt = printDDMMYYYYHHMM((plot as Plot).created_at) ?? '';
  const modifiedAt = printDDMMYYYYHHMM((plot as Plot).modified_at) ?? '';
  const analysedAt = printDDMMYYYYHHMM((plot as Plot).last_analyzed_at) ?? '';

  const editedAt =
    modifiedAt > createdAt
      ? t('shared.plots.tooltips.edited', { timestamp: modifiedAt })
      : t('shared.plots.tooltips.uploaded', { timestamp: createdAt });
  const lastActionHint = analysedAt ? t('shared.plots.tooltips.analysed', { timestamp: analysedAt }) : editedAt;

  return (
    <Stack direction='row' centerMain spacing={3}>
      {'status' in plot && plot.status === PlotStatusEnum.invalid ? (
        <span className='flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-bg-light-grey'>
          <RiErrorWarningFill size={20} className='text-error' data-testid='alert-icon' />
        </span>
      ) : (
        <PlotThumbnail plot={plot} className='h-12 w-12 shrink-0 rounded-lg' />
      )}
      <Tooltip>
        <TooltipTrigger>
          <Stack direction='col' spacing={2} className='max-w-[150px] items-start'>
            <span className='max-w-full truncate'>{plot.name}</span>
            {'status' in plot && (
              <Stack direction='row' spacing={2} className='typography-body2 items-center'>
                <RiCircleFill size={12} color={getColorOfPlotStatus(plot.status)} />
                <span className='max-w-[125px] truncate'>{t(`global.plotStatus.${plot.status}`)}</span>
              </Stack>
            )}
          </Stack>
        </TooltipTrigger>
        <TooltipContent sideOffset={5} side='top'>
          {lastActionHint}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </Stack>
  );
};

export const AreaCell = ({ row }: CellContext<Plot, unknown> | CellContext<DraftPlot, unknown>) => {
  const plot = row.original;
  const isValid = 'status' in plot ? plot.status !== PlotStatusEnum.invalid : true;

  const areaInHectares = getDisplayNumber(squareMetersToHectares(plot.area), window.navigator.language);

  return <span className='whitespace-nowrap'>{isValid ? `${areaInHectares} ha` : '--'}</span>;
};

export const PlotTypeCell = ({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;
  const { t } = useTranslation();

  const plotTypeHint = useMemo(() => {
    const hasCrops = plot.type === PlotType.CROPLAND && plot.crops && plot.crops.length > 0;
    const crops = plot.crops
      .slice(0, maxDisplayCropCount)
      .map((crop) => t(`global.crops.${crop}`))
      .sort()
      .join(', ');
    return hasCrops ? t('shared.plots.tooltips.cropland', { crops }) : t(`global.plotTypes.${plot.type}`);
  }, [t, plot.type, plot.crops]);
  return (
    <Tooltip>
      <TooltipTrigger>
        <PlotTypeIcon type={plot.type} size={24} />
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='top'>
        {plotTypeHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

type FactCellProps = {
  plot: Plot;
  factType: R1FactType;
  unitType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
};
// TODO: MVP-3284 use instead of specific fact cells
// TODO: MVP-3284 add PlotListNotice as override in parent or as prop
export const FactCell: FC<FactCellProps> = withSuspenseBoundary(({ plot, factType, unitType, icon: Icon }) => {
  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const fact = getFact<number>(factType);

  let formattedFact = '--';

  const value = useDisplayNumber(fact?.value ?? 0);
  const unit = formatUnit(unitType as UnitEnum);

  if (fact?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }

  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={fact?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid={`${factType}-cell`}
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={Icon && <Icon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
}, <PendingPill />);

type FactTrendCellProps = {
  plot: Plot;
  factType: R1FactType;
  unitType: string;
};

export const FactTrendCell: FC<FactTrendCellProps> = withSuspenseBoundary(({ plot, factType, unitType }) => {
  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const fact = getFact<number>(factType);

  let formattedFact = '--';

  const value = useDisplayNumber(fact?.value ?? 0);
  const unit = formatUnit(unitType as UnitEnum);

  if (fact?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }

  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  const trendIcon = getTrendIcon(fact);
  const trendIconColor = getTrendIconColor(fact);
  const trendTextColor = getTrendTextColor(fact);

  return (
    <FactTooltip showTooltip={fact?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid={`${factType}-trend-cell`}
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: trendTextColor || getTextColorOfPill(plot.status),
        }}
        leftAdornment={
          trendIcon && (
            <span style={{ color: trendIconColor || getTextColorOfPill(plot.status) }} className='h-3 w-3'>
              {trendIcon}
            </span>
          )
        }
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
}, <PendingPill />);

export const getTrendIcon = (fact: PlotReportFactElement | undefined) => {
  if (!fact) {
    return null;
  }
  if ((fact.value as number) === 0) return null;
  if ((fact.value as number) > 0) return <RiArrowRightUpLine />;
  return <RiArrowRightDownLine />;
};

export const BiodiversityZoneCell = withSuspenseBoundary(({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const biodiversityZone = getFact<number>(R1FactType.r1_biodiversity_zone_percent);

  let formattedFact = '--';

  const value = useDisplayNumber(biodiversityZone?.value ?? 0);
  const unit = formatUnit(UnitEnum['%']);

  if (biodiversityZone?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }

  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={biodiversityZone?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid='bio-cell'
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={<BiodiversityIcon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
}, <PendingPill />);

export const CarbonStorageBgCell = withSuspenseBoundary(({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const carbonStorageBg = getFact<number>(R1FactType.r1_carbon_storage_bg_per_ha);

  let formattedFact = '--';

  const value = useDisplayNumber(valueToTonne(carbonStorageBg?.value, carbonStorageBg?.unit) ?? '');
  const unit = formatUnit(UnitEnum['t/ha']);

  if (carbonStorageBg?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }

  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={carbonStorageBg?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid='carbon-cell'
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={<Co2Icon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
}, <PendingPill />);

export const WaterHoldingCapacityCell = withSuspenseBoundary(({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const waterHoldingCapacity = getFact<number>(R1FactType.r1_water_holding_capacity_per_ha);

  let formattedFact = '--';

  const value = useDisplayNumber(valueToTonne(waterHoldingCapacity?.value, waterHoldingCapacity?.unit) ?? '');
  const unit = formatUnit(UnitEnum['m^3/ha']);

  if (waterHoldingCapacity?.value != null) {
    formattedFact = unit ? `${value} ${unit}` : `${value}`;
  }
  const isOutdatedValue = formattedFact !== '--' && plot.status !== PlotStatusEnum.analysed;

  return (
    <FactTooltip showTooltip={waterHoldingCapacity?.value === null && plot.status === PlotStatusEnum.ready_to_analyse}>
      <Pill
        data-testid='whc-cell'
        size='small'
        style={{
          backgroundColor: getColorOfPill(isOutdatedValue),
          color: getTextColorOfPill(plot.status),
        }}
        leftAdornment={<WaterIcon size={12} color={getTextColorOfPill(plot.status)} />}
      >
        {formattedFact}
      </Pill>
    </FactTooltip>
  );
}, <PendingPill />);

type FactTooltipProps = PropsWithChildren & {
  showTooltip: boolean;
};

export const FactTooltip: FC<FactTooltipProps> = ({ children, showTooltip }) => {
  const { t } = useTranslation();
  if (showTooltip) {
    return (
      <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent sideOffset={5} side='top'>
          {t('shared.plots.tooltips.analyseProject')}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  }
  return <>{children}</>;
};

export const PlotNotice = (plot: Plot) => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();
  const { pathname } = useLocation();

  const projectId = useProjectId();
  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');
  const plotStatus = plot.status;

  const noticeLabel = () => {
    const exhaustivenessCheck = (status: never) => {
      Logger.error(`No notices defined for plot status "${status}"`);
      return null;
    };

    switch (plotStatus) {
      case PlotStatusEnum.invalid:
        if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
          return `${t('shared.plots.plotNotice.invalidPlot.info')} ${t(
            'shared.plots.plotNotice.invalidPlot.action.info',
          )}`;
        }
        return t('shared.plots.plotNotice.invalidPlot.info');
      case PlotStatusEnum.new_plot:
        return t('shared.plots.plotNotice.newPlot.info');
      case PlotStatusEnum.draft:
        return t('shared.plots.plotNotice.draftPlot.info');
      case PlotStatusEnum.analysed:
      case PlotStatusEnum.calculating:
      case PlotStatusEnum.ready_to_analyse:
      case PlotStatusEnum.scheduled_for_analysis:
        return null;
      default:
        return exhaustivenessCheck(plotStatus);
    }
  };

  const actionLabel = () => {
    const exhaustivenessCheck = (status: never) => {
      Logger.error(`No actions defined for plot status "${status}"`);
      return null;
    };

    switch (plotStatus) {
      case PlotStatusEnum.invalid:
        return t('shared.plots.plotNotice.invalidPlot.action.label');
      case PlotStatusEnum.new_plot:
        return t('shared.plots.plotNotice.newPlot.action.label');
      case PlotStatusEnum.draft:
        return t('shared.plots.plotNotice.draftPlot.action.label');
      case PlotStatusEnum.analysed:
      case PlotStatusEnum.calculating:
      case PlotStatusEnum.ready_to_analyse:
      case PlotStatusEnum.scheduled_for_analysis:
        return null;
      default:
        return exhaustivenessCheck(plotStatus);
    }
  };
  return (
    <Alert
      className='max-w-[600px] overflow-visible p-0'
      style={{
        backgroundColor: getColorOfPlotStatusNotice(plotStatus),
        color: getTextColorOfPlotStatusNotice(plotStatus),
      }}
      data-testid='plot-list-action-notice'
    >
      <AlertBody className='flex h-12 min-w-0 flex-row items-center justify-between gap-2'>
        <span className='flex-1 truncate'>{noticeLabel()}</span>
        {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
          (isProjectEditable ? (
            <ButtonLink
              data-testid='plot-list-action-notice-cta'
              variant='text'
              className='typography-button2 px-2 py-2'
              style={{
                color: getTextColorOfPlotStatusNotice(plotStatus),
              }}
              state={{
                previousPath: pathname,
              }}
              onClick={(e) => e.stopPropagation()}
              to={buildPath(paths.landSteward.editPlot, { pathParams: { projectId, plotId: plot.id } })}
              preventScrollReset
            >
              {actionLabel()}
            </ButtonLink>
          ) : (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  data-testid='plot-list-action-notice-cta'
                  disabled
                  variant='text'
                  className='typography-button2 px-2 py-2'
                  style={{
                    color: getTextColorOfPlotStatusNotice(plotStatus),
                  }}
                >
                  {actionLabel()}
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={5} side='bottom'>
                {t('shared.projects.plot.tooltips.disabledEditOrDeletePlot')}
                <TooltipArrow className='fill-white-100' />
              </TooltipContent>
            </Tooltip>
          ))}
      </AlertBody>
    </Alert>
  );
};
