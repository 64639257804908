import { Stack } from 'component-library';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';

import { NoProjectData } from '../../../components/NoProjectData';
import { Tile, TileCapsuleContent, TileCapsuleTitle, TileCta, TileHelper } from './Tile';

export const DeforestationTile = () => {
  const { t } = useTranslation();
  const project = useProject().data;

  const pathSet =
    useMembershipType() === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;
  const totalPlotCount = usePlotsForProject().data.results.length;
  const deforestedPlotCount = useNCData<number>(R1FactType.r1_deforestation_occurred_since_2020)?.value;

  const deforestationActivityDisplay = useMemo(() => {
    if (deforestedPlotCount === 0) {
      return (
        <span className='typography-h3'>
          {t('shared.ncaDetail.details.deforestation.noDeforestationActivityDetected')}
        </span>
      );
    }

    return (
      <span className='typography-h3'>
        {t('shared.ncaDetail.details.deforestation.deforestationActivityCount', {
          affectedCount: deforestedPlotCount,
          totalCount: totalPlotCount,
        })}
      </span>
    );
  }, [t, deforestedPlotCount, totalPlotCount]);

  const noChartData = deforestedPlotCount == null;

  if (noChartData) {
    return <NoProjectData />;
  }

  const hasDeforestedPlots = deforestedPlotCount > 0;

  return (
    <Tile
      chart={<Chart affectedCount={deforestedPlotCount} totalCount={totalPlotCount} />}
      capsuleTitle={<TileCapsuleTitle>{t('global.analysis.deforestationActivity')}</TileCapsuleTitle>}
      capsuleContent={<TileCapsuleContent>{deforestationActivityDisplay}</TileCapsuleContent>}
      cta={
        hasDeforestedPlots && (
          <TileCta to={buildPath(pathSet.projectDetailsDeforestation, { pathParams: { projectId: project.id } })}>
            {t('shared.projects.project.landMonitoring.deforestation.labels.seeMoreDetailsDeforestation')}
          </TileCta>
        )
      }
      helper={
        hasDeforestedPlots && (
          <TileHelper>{t('shared.projects.project.landMonitoring.deforestation.helper')}</TileHelper>
        )
      }
    />
  );
};

export const Chart = ({ affectedCount, totalCount }: { affectedCount: number; totalCount: number }) => {
  /**
   * We show a maximum of 100 total dots
   */
  const affectedPlotsCount = Math.min(affectedCount, 100);
  const remainingPlotsCount =
    affectedPlotsCount === 100 ? 0 : Math.min(100 - affectedPlotsCount, totalCount - affectedPlotsCount);
  return (
    <Stack
      direction='row'
      spacing='5px'
      className='flex-wrap content-start md:min-h-[80px]'
      style={{ '--dot-size': affectedCount + totalCount > 50 ? '14px' : '20px' } as CSSProperties}
    >
      {new Array(affectedPlotsCount || 0).fill(null).map((_, index) => (
        <div
          key={index}
          data-testid='affected-plot-dot'
          className='h-[var(--dot-size)] w-[var(--dot-size)] rounded-full border-2 border-warning-dark bg-warning-light'
        />
      ))}
      {new Array(remainingPlotsCount || 0).fill(null).map((_, index) => (
        <div
          key={index}
          data-testid='remaining-plot-dot'
          className='h-[var(--dot-size)] w-[var(--dot-size)] rounded-full border-2 border-landtypes-shrubland-dark bg-landtypes-shrubland-main'
        />
      ))}
    </Stack>
  );
};
