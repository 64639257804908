import { cn, RiAlertFill, RiCheckboxCircleFill, RiErrorWarningFill, WaitClockIcon } from 'component-library';
import React, { PropsWithChildren } from 'react';

import { exhaustivenessCheck } from '@/utils/exhaustivenessCheck';

type StatusProps = {
  variant: 'success' | 'warning' | 'error' | 'in-progress';
  'data-cy'?: string;
};

export const AccountStatus: React.FC<PropsWithChildren<StatusProps>> = ({ children, variant, ...props }) => {
  const textColorClass = (() => {
    switch (variant) {
      case 'success':
        return 'text-primary-100';
      case 'in-progress':
        return 'text-info-dark';
      case 'warning':
        return 'text-warning-dark';
      case 'error':
        return 'text-error';
      default:
        return exhaustivenessCheck(variant);
    }
  })();

  return (
    <div
      className={cn('typography-caption flex flex-row items-center gap-2 py-1', textColorClass)}
      data-cy='kyc-status'
      {...props}
    >
      <StatusIcon variant={variant} />
      {children}
    </div>
  );
};

type StatusIconProps = {
  variant: StatusProps['variant'];
};

const StatusIcon: React.FC<StatusIconProps> = ({ variant }) => {
  switch (variant) {
    case 'success':
      return <RiCheckboxCircleFill size={18} className='text-primary-light' />;
    case 'in-progress':
      return <WaitClockIcon size={18} className='text-info' />;
    case 'warning':
      return <RiAlertFill size={18} className='text-warning' />;
    case 'error':
      return <RiErrorWarningFill size={18} />;
    default:
      return exhaustivenessCheck(variant);
  }
};
